import React from 'react';
import { Subject } from 'rxjs';
import './Header.scss';

import Button from '../../../common/components/Button/Button';
import { EnumButtonType } from '../../../common/enums/EnumButtonType';
import { EnumButtonSize } from '../../../common/enums/EnumButtonSize';
import NewsletterSubscriptionForm from '../NewsletterSubscriptionForm/NewsletterSubscriptionForm';
import ModalContainer from '../../../common/components/Modal/Container/Container';
import { Link } from 'gatsby';
import { Constants } from '../../../common/constants';
import trackEvent from '../../../common/services/analytics.service';

function Header(props: { logoUrl: string }) {
  const modalEvent = new Subject<void>();
  modalEvent.subscribe({
    next: () => {
      trackEvent(Constants.Segment.Action.Subscribe, {
        action: Constants.Segment.Action.Subscribe,
        label: window.location.pathname,
        category: Constants.Segment.Category.Newsletter
      })
    }
  });

  const trackLogoEvent = () => {
    trackEvent(Constants.Segment.Action.Logo, {
      action: Constants.Segment.Action.Logo,
      label: window.location.pathname,
      category: Constants.Segment.Category.Navigation
    })
  };

  return (
    <header className="Header">
      <Link to="/"> <img src={props.logoUrl} onClick={trackLogoEvent} className="Header__logo" alt="logo" /></Link>
      {/*
      <div className="Header__button">
        <Button
          text='Subscribe'
          type={EnumButtonType.Primary}
          size={EnumButtonSize.small}
          event={modalEvent}
          asAction={true} />
      </div>
      <ModalContainer event={modalEvent}>
        <NewsletterSubscriptionForm />
      </ModalContainer>
      */}
    </header>
  );
}

export default Header;