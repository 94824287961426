import React from 'react';
import SEO from '../components/SEO/seo';
import MainLayout from './MainLayout/MainLayout';
import './index.scss';

function layoutWrapper({ children, pageContext }: { children: any; pageContext: { id: string, contentType: string }; }) {
  return <><SEO /><MainLayout>{children}</MainLayout></>;
}

export default layoutWrapper;
