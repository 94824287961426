import React from 'react';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './MainLayout.css';

const MainLayout = ({ children }: { children: any }) => {
  return (
    <div className="App">
      <Header logoUrl='/logo.svg' />
      <div className="children">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;
