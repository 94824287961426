export class Constants {
  static get Segment() {
    return {
      Category: {
        Navigation: 'Navigation',
        Resources: 'Resources',
        Category: 'Category',
        Access: 'Access',
        Newsletter: 'Newsletter',
        SocialShare: 'Social Share',
        WatchVideo: 'WatchVideo',
        VideoStart: 'VideoStart',
        VideoComplete: 'VideoComplete',
        VideoPercentView: 'VideoPercentView',
        ScrollDepth: 'Scroll Depth',
      },
      Action: {
        Footer: 'Footer',
        Logo: 'Logo',
        ViewAll: 'View all',
        BackToHome: 'Back to home',
        Exit: 'Exit',
        Subscribe: 'Subscribe',
        Submit: 'Submit'
      },
      Label: {
        Download: 'Download'
      }
    }
  }

  static get GeneralStrings() {
    return {
      watch: 'Watch',
      featureResources: 'Featured Resources',

    }
  }

  static get Paths() {
    return {
      resources: '/resource/',
      categories: '/categories/'
    }
  }

  static get WalmartCaresBaseUrl() {
    return 'https://mywalmartcares.com'
  }

  static get productionEnvName() {
    return 'production'
  }
}
