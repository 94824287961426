import React from 'react';
import './Footer.scss';
import { Constants } from '../../../common/constants';
import trackEvent from '../../../common/services/analytics.service';

export const Footer = () => {
  const trackFooterEvent = () => {
    trackEvent(Constants.Segment.Action.Footer, {
      category: Constants.Segment.Category.Navigation,
      label: 'Contact us',
      action: Constants.Segment.Action.Footer
    });
  };

  return (
    <div className="Footer" onClick={trackFooterEvent}>
      <div className="Footer-copy">
        For questions, &nbsp;
          <a
          href="mailto:benzp@walmart.com"
          className="Footer-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
          </a>
      </div>
    </div>
  );
}

export default Footer;