import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

interface ISEO {
  title?: string;
  description?: string;
  image?: string;
  appleTouchIcon?: string;
  themeColor?: string;
  blogPublic?: string;
}

const SEO = ({
  title,
  description,
  image,
  twitterDescription,
  twitterTitle,
  facebookTitle,
  facebookDescription,
  twitterImage
}: ISEO) => {
  const { pathname } = useLocation()
  const { site, wordpressWpStatuses } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    themeColor,
    appleTouchIcon,
  } = site.siteMetadata
  const blogPublic = wordpressWpStatuses.blog_public
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    twTitle: `${twitterTitle || title}`,
    twDescription: `${twitterDescription || description}`,
    twImage: `${twitterImage || image}`,
    fbTitle: `${facebookTitle || title}`,
    fbDescription: `${facebookDescription || description}`,
    url: `${siteUrl}${pathname}`,
    themeColor,
    appleTouchIcon,
    blogPublic,
  }

  return (
    <Helmet title={seo.title}>
      <link rel="icon" href={`${seo.url}favicon.ico`} />

      {seo.themeColor && <meta name="theme-color" content={seo.themeColor} />}

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {seo.fbTitle && <meta property="og:title" content={seo.fbTitle} />}

      {seo.fbDescription && (
        <meta property="og:description" content={seo.fbDescription} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta property="twitter:card" content="summary_large_image" />

      {seo.twTitle && <meta name="twitter:title" content={seo.twTitle} />}

      {seo.twDescription && (
        <meta property="twitter:description" content={seo.twDescription} />
      )}

      {seo.twImage && <meta property="twitter:image" content={seo.twImage} />}

      {`<!--
        manifest.json provides metadata used when your web app is installed on a
        user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
      -->`}

      {seo.url && <link rel="manifest" href={`${seo.url}manifest.json`} />}

      {seo.blogPublic === "0" && <meta name="robots" content="noindex, nofollow" />}

      <link rel="apple-touch-icon" href={`${seo.url}${seo.appleTouchIcon}`} />
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        themeColor,
        appleTouchIcon
      }
    }
      wordpressWpStatuses {
          blog_public,
      }
  }
`
