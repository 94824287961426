interface SegmentTaxonomy {
  category: string;
  label: string;
  action: string | number;
}

const trackEvent = (name: string, { category, label, action }: SegmentTaxonomy) => {
  window.analytics.track(name, {
    action,
    label,
    category
  });
}

export default trackEvent;